import React, { Component } from 'react';
import Header from '../components/header.js';
import { Link, withRouter } from "react-router-dom";
import '../App.scss';
import givingaLogo from '../images/givinga_logo.png';

class Failure extends Component {
  render() {
    return (
      <div>
      <Header/>
        <div className="page-container">
          <div className="page-container-header-img">
          <center><img src={givingaLogo}></img></center>
          </div>
          <div className="page-container-title">
          <p>We're sorry, something went wrong during your onboarding. Please contact support@givinga.com for help.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Failure);
