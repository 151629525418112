import React, { Component } from 'react';
import Header from './components/header.js';
import axios from 'axios';
import qs from 'querystring';
import { Link, withRouter } from "react-router-dom";
import './App.scss';
import givingaLogo from './images/givinga_logo.png';
import stripeLogo from './images/powered_by.png';
const config = {
  headers: {
    'Content-Type': "application/json",
    'Access-Control-Allow-Origin': '*'
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: "",
      name: "",
      address: "",
      phone: "",
      taxId: ""
    };

    this.emailUpdate = this.emailUpdate.bind(this);
    this.nameUpdate = this.nameUpdate.bind(this);
    this.addressUpdate = this.addressUpdate.bind(this);
    this.phoneUpdate = this.phoneUpdate.bind(this);
    this.taxIdUpdate = this.taxIdUpdate.bind(this);
  }

  emailUpdate(event) {
    this.setState({email: event.target.value})
  }

  nameUpdate(event) {
    this.setState({name: event.target.value})
  }

  addressUpdate(event) {
    this.setState({address: event.target.value})
  }

  phoneUpdate(event) {
    this.setState({phone: event.target.value})
  }

  taxIdUpdate(event) {
    this.setState({taxId: event.target.value})
  }

  handleSubmit = async (event) => {
    if (this.state.name === "" || this.state.email === "" || this.state.phone === "" || this.state.taxId === "") {
      this.setState({ processing: false });
      alert("Please fill out all required fields.")
      event.preventDefault();
      return;
    }
    this.setState({ processing: true });
    event.preventDefault();

    const createAccount = {
      "email": this.state.email,
      "requested_capabilities": ['card_payments', 'transfers'],
      "company": {
        "name": this.state.name,
        "phone":  this.state.phone,
        "tax_id": this.state.taxId,
      }
    }

    const linkedAccount = await axios.post('https://givinga-connect-api.herokuapp.com/account-create', createAccount, config)
    .catch(error => {
      console.log(error);
      this.setState({ processing: false });
      return;
    });

    if (linkedAccount) {
      //send the user to the onboarding url
      window.location = linkedAccount.data.url
    } else {
      alert("Something went wrong");
    }
  };

  render() {
    return (
      <div>
      <Header/>
        <div className="page-container">
          <div className="page-container-header-img">
          <center><img src={givingaLogo}></img></center>
          </div>
          <div className="page-container-title">
            <center>
            <p>Register for your Givinga Connect Account</p>
            <img className="stripe" src={stripeLogo}></img>
            <form onSubmit={this.handleSubmit}>
            <div className="checkout-form">
              <input placeholder="Company Name*" value={this.state.name} onChange={this.nameUpdate}/>
              <input placeholder="Company Phone*" value={this.state.phone} onChange={this.phoneUpdate}/>
              <input placeholder="Company Tax ID*" value={this.state.taxId} onChange={this.taxIdUpdate}/>
              <input placeholder="Email*" value={this.state.email} onChange={this.emailUpdate}/>
            </div>
            <button className="page-container-title-button" type="submit" disabled={this.state.processing}>{this.state.processing ? "Registering..." : "Register"}</button>
            </form>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
