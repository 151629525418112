import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import ReactDOM from 'react-dom';
import { Route, Router} from 'react-router-dom';
import App from './App';
import Success from './pages/page-success';
import Failure from './pages/page-failure';

import * as serviceWorker from './serviceWorker';
import history from './history';

const routing = (
  <Router history={history}>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/failure" component={Failure} />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
